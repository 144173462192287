import React from 'react';
import { Tabs } from 'antd';
import { GenericReportingDropdowns } from '../../containers/GenericReportingDropdowns';
import Google from './Google';
import Facebook from './Facebook';
import MediaInvestmentPerformance from './MediaInvestmentPerformance';

function PaidMediaPage() {

    const tabs = [
        {
            label: 'Google',
            key: 'Google',
            children: [<Google></Google>]
        },
        {
            label: 'Facebook',
            key: 'Facebook',
            children: [<Facebook></Facebook>]
        },
        {
            label: 'Media Investment Performance',
            key: 'Media Investment Performance',
            children: [<MediaInvestmentPerformance></MediaInvestmentPerformance>]
        }
    ]

    return (
        // JSX code for your component's UI goes here
        <div style={{
            'display': 'flex',
            'flexDirection': 'column',
            'justifyContent': 'center',
            'width': '98vw',
            'alignSelf': 'center',
            'marginLeft': '69.2vw',
        }}>
            <GenericReportingDropdowns></GenericReportingDropdowns>
            <Tabs style={{
                'width': '95vw',
                'marginLeft': '1vw',
            }} items={tabs} ></Tabs>
        </div>
    );
}

export default PaidMediaPage;