import React from "react";

import { GenericReportingDropdowns } from "../containers/GenericReportingDropdowns";
import GoogleAdsReportingCharts from "../containers/GoogleAdsReportingCharts";
import ShopifyReportingCharts from "../containers/ShopifyReportingCharts";
import { Tabs } from "antd";

export const MarketingReportingPage = () => {

    const tabs = [
        {
            label: 'Shopify Report',
            key: 'Shopify Report',
            children: [<ShopifyReportingCharts></ShopifyReportingCharts>]
        },
        {
            label: 'Ads Report',
            key: 'Ads Report',
            children: [<GoogleAdsReportingCharts></GoogleAdsReportingCharts>]
        }
    ]

    return (
        <div style={{
            'display': 'flex',
            'flexDirection': 'column',
            'justifyContent': 'center',
            'width': '98vw',
            'alignSelf': 'center',
            'marginLeft': '69.2vw',
        }}>
        <GenericReportingDropdowns></GenericReportingDropdowns>
            <Tabs style={{
                'width': '90vw'
            }} items={tabs} ></Tabs>
        </div>
    );
}