import React from 'react';
import { useSelector } from 'react-redux';
import { useGetRevenueSplitByNewReturningPlotQuery, useGetUniqueShopifyCountriesQuery } from '../../redux/api/genericReporting';
import PlotContainer from '../PlotContainer';
import '../containerStyles.css';
import { Select } from 'antd';
import { useState } from 'react';

const ExecutiveSummaryRevenueChart = () => {
    
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const selectedDateGranularity = useSelector((state) => state.genericReporting.dateGranularity);

    const [selectedCountry, setSelectedCountry] = useState('All');

    const uniqueShopifyCountries = useGetUniqueShopifyCountriesQuery({
        client: selectedClient,
    });

    return (
        // Your JSX code goes here
        <div className='flexible-container' style={{}}>
            <div>
                <h3 style={{'marginLeft': '1.2vw'}}>Filter By Country</h3>
                <Select showSearch={true} placeholder='Select a country' style={{ width: 200,
                    'marginLeft': '1.2vw' }} value={selectedCountry} onChange={(val) => {
                    setSelectedCountry(val);
                }} >
                    {uniqueShopifyCountries.data && uniqueShopifyCountries.data.map((country) => {
                        return <Select.Option key={country} value={country}>{country}</Select.Option>
                    })}
                </Select>
                <div className='full-width-container' style={{
                    'width': '100%',
                }}>
                    <PlotContainer tooltipTitle='Revenue Split by New and Returning' useApi={useGetRevenueSplitByNewReturningPlotQuery} useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    country: selectedCountry,
                    granularity: selectedDateGranularity
                    }} />
                </div>
            </div>
        </div>
    );
};

export default ExecutiveSummaryRevenueChart;