import React from 'react';
import Upload from 'antd/es/upload/Upload';
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import './compassInputs.css';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import axios from 'axios';

const CompassInputs = () => {

    const [inputsFile, setInputsFile] = React.useState(null);
    const [modellingFile, setModellingFile] = React.useState(null);
    const [responseCurvesFile, setResponseCurvesFile] = React.useState(null);
    const [spendFile, setSpendFile] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [response, setResponse] = React.useState(<></>);


    const selectedProject = useSelector((state) => state.compass.selectedProject);
    const apiToken = useSelector((state) => state.oauth.jwt_token);

    function getUpload({title, setFunction}) {
        return (
            <div style={{
                'justifyContent': 'center',
                'display': 'flex',
                'flexDirection': 'column',
                'textAlign': 'center',
                'margin': '2rem'
            }}>
                <h3>Upload {title}</h3>
                <Upload multiple='false' listType='text' beforeUpload={(file) => {
                    setFunction(file);
                    return false;
                }
            } >
                <div className='uploadDiv'>
                <button style={{ border: 0, background: 'none', cursor: 'grab' }} type="button">
                    <PlusOutlined />
                    <div>Upload</div>
                </button>
            </div>
                </Upload>
            </div>
        );
    }

    function onSubmit() {

        console.log('onsubmit')
        console.log(inputsFile)

        setLoading(true);

        var bodyFormData = new FormData();
        bodyFormData.append('compass_input', inputsFile);
        bodyFormData.append('compass_modelling', modellingFile);
        bodyFormData.append('compass_response_curves', responseCurvesFile);
        bodyFormData.append('compass_spend', spendFile);

        // using axios because the rtk refuses to acknowledge that multipart form data exists
        axios.post(process.env.REACT_APP_API_URL + '/compass_upload_files?project_id=' + selectedProject.project_id, bodyFormData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + apiToken
            }
        }).then((response) => {
            console.log(response);
            setResponse(<div>Files uploaded successfully</div>);
            setLoading(false);
        }
        ).catch((error) => {
            console.log(error);
            setResponse(<div>Failed to upload files</div>);
            setLoading(false);
        }
        );
    }

    return (
        // JSX code for your component's UI goes here
        <div style={{
            'display': 'flex',
            'flexDirection': 'row',

        }}>
            {getUpload({ title: 'Inputs', setFunction: setInputsFile})}
            {getUpload({ title: 'Modelling Files', setFunction: setModellingFile})}
            {getUpload({ title: 'Response Curves', setFunction: setResponseCurvesFile})}
            {getUpload({ title: 'Spend', setFunction: setSpendFile})}
            <div style={{
                'justifyContent': 'center',
                'display': 'flex',
                'flexDirection': 'column',
                'textAlign': 'center',
                'margin': '2rem'
            }}>
                <Button onClick={onSubmit}>Submit Data</Button>
                {loading ? <LoadingOutlined /> : <></>}
                {response}
            </div>
        </div>
    );
};

export default CompassInputs;