import React from 'react';
import { useGetDataTablesQuery } from '../../redux/api/compassProject';
import TableContainer from '../TableContainer';
import { useSelector } from 'react-redux';

function CompassData() {

    const selectedProject = useSelector((state) => state.compass.selectedProject);

    return (
        <div>
            <TableContainer useApi={useGetDataTablesQuery} apiParams={{
                project_id: selectedProject.project_id,
                data_type: 'input'
            }} title="Inputs table" />
            <TableContainer useApi={useGetDataTablesQuery} apiParams={{
                project_id: selectedProject.project_id,
                data_type: 'modelling'
            }} title="Modelling table" />
            <TableContainer useApi={useGetDataTablesQuery} apiParams={{
                project_id: selectedProject.project_id,
                data_type: 'response_curves'
            }} title="Response curves table" />
            <TableContainer useApi={useGetDataTablesQuery} apiParams={{
                project_id: selectedProject.project_id,
                data_type: 'spend'
            }} title="Spend table" />
        </div>
    );
}

export default CompassData;