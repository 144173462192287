import Tooltips from "../../Tooltips"
import { Tooltip } from "antd"
import React from "react"
import { InfoCircleOutlined } from "@ant-design/icons";

export default function TooltipContainer({
    style = {
        'alignSelf': 'flex-end',
        'margin': '0',
        'width': '0.8vw',
        'height': '2.0vh',
        'display': 'inline',
    },
    title,
    invertColor=false,
}) {

    const data = Tooltips[title];

    return (
        <div style={style} className='info-box'>
            <Tooltip title={
                <>
                    <h3 style={{
                        'color': 'white',
                        'marginTop': '0',
                    }}>
                        {title}
                    </h3>
                    <p style={{
                        'color': 'white',
                        'marginBottom': '0',
                    }}>
                        {data}
                    </p>
                </>
            } >
                <InfoCircleOutlined style={{
                    'color': invertColor ? 'white' : 'black',
                    'fontSize': '1.0rem',
                }} />
            </Tooltip>
        </div>
    )
}