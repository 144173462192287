import React from 'react';
import { useSelector } from 'react-redux';
import { useGetPaidChannelComparisonPerformanceChartQuery, useGetPaidChannelComparisonPerformanceTimeSeriesQuery, useGetPaidChannelTableQuery } from '../../redux/api/compassProject';
import PlotContainer from '../PlotContainer';
import TableContainer from '../TableContainer';

const PaidChannelComparison = () => {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const selectedDateGranularity = useSelector((state) => state.genericReporting.dateGranularity);
    const selectedDrivers = useSelector((state) => state.compass.drivers);

    return (
        <div>
            <div className='full-width-container'>
                <PlotContainer useApi={useGetPaidChannelComparisonPerformanceChartQuery} useApiArgs={{
                    client_name: selectedClient,
                    start_date: selectedStartDate,
                    end_date: selectedEndDate,
                    granularity: selectedDateGranularity,
                    drivers: selectedDrivers
                }} />
            </div>
            <div className='full-width-container'>
                <PlotContainer useApi={useGetPaidChannelComparisonPerformanceTimeSeriesQuery} useApiArgs={{
                    client_name: selectedClient,
                    start_date: selectedStartDate,
                    end_date: selectedEndDate,
                    granularity: selectedDateGranularity,
                    drivers: selectedDrivers
                }} />
            </div>
            <div className='full-width-container'>
                <TableContainer useApi={useGetPaidChannelTableQuery} apiParams={{
                    client_name: selectedClient,
                    start_date: selectedStartDate,
                    end_date: selectedEndDate,
                    granularity: selectedDateGranularity,
                    drivers: selectedDrivers
                }} />
            </div>
        </div>
    );
};

export default PaidChannelComparison;