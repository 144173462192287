import React from 'react';
import { GenericReportingDropdowns } from '../containers/GenericReportingDropdowns';
import { Tabs } from 'antd';
import LifetimeRevenue from '../containers/CustomerRetention/LifetimeRevenue';
import CohortedRetention from '../containers/CustomerRetention/CohortedRetention';
import CategoricalLtr from '../containers/CustomerRetention/CategoricalLtr';

const RetentionPage = () => {

    const tabs = [
        {
            label: 'Lifetime Revenue',
            key: 'Lifetime Revenue',
            children: [<LifetimeRevenue></LifetimeRevenue>]
        },
        {
            label: 'Cohorted Retention',
            key: 'Cohorted Retention',
            children: [<CohortedRetention></CohortedRetention>]
        },
        {
            label: 'Categorical LTR',
            key: 'Categorical LTR',
            children: [<CategoricalLtr></CategoricalLtr>]
        }
    ]

    return (
        <div>
            <div style={{
                'display': 'flex',
                'flexDirection': 'column',
                'justifyContent': 'center',
                'width': '98vw',
                'alignSelf': 'center',
                'marginLeft': '69.2vw',
            }}>
                <GenericReportingDropdowns includeDateRange={false}></GenericReportingDropdowns>
                <Tabs style={{
                    'width': '90vw'
                }} items={tabs} ></Tabs>
            </div>
        </div>
    );
};

export default RetentionPage;