import React from 'react';
import { useSelector } from 'react-redux';
import { useGetTableQuery, useGetChartQuery, useGetRoiChartQuery } from '../redux/api/adsReporting';
import TableContainer from './TableContainer';
import PlotContainer from './PlotContainer';
import GoogleAdsRankings from './GoogleAdsRankings';

const GoogleAdsReportingCharts = () => {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);

    return (
        <div>
            <TableContainer height='60vh' useApi={useGetTableQuery} apiParams={{
                client:
                    selectedClient,
                startDate:
                    selectedStartDate,
                endDate:
                    selectedEndDate
            }} ></TableContainer>
            <br></br>
            <PlotContainer useApi={useGetChartQuery} useApiArgs={{
                client:
                    selectedClient,
                startDate:
                    selectedStartDate,
                endDate:
                    selectedEndDate
            }} ></PlotContainer>
            <br></br>
            <PlotContainer useApi={useGetRoiChartQuery} useApiArgs={{
                client:
                    selectedClient,
                startDate:
                    selectedStartDate,
                endDate:
                    selectedEndDate
            }} ></PlotContainer>
            <GoogleAdsRankings></GoogleAdsRankings>
        </div>
    );
};

export default GoogleAdsReportingCharts;