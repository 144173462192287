import { applyMiddleware, compose } from 'redux';
import { userApi } from './api/user'
import { shopifyReportingApi } from './api/shopifyReporting'
import { adsReportingApi } from './api/adsReporting';
import { claimsApi } from './api/claims';
import { compassProjectApi } from './api/compassProject';
import { gaFunnelingApi } from './api/gaFunneling';
import { genericReportingApi } from './api/genericReporting';

const combinedApiMiddleware = [
    adsReportingApi.middleware,
    userApi.middleware,
    shopifyReportingApi.middleware,
    claimsApi.middleware,
    compassProjectApi.middleware,
    gaFunnelingApi.middleware,
    genericReportingApi.middleware];

export default combinedApiMiddleware;
