import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQuery, // Replace '/api' with your actual API base URL
    endpoints: (builder) => ({
        getUser: builder.query({
            query: () => '/user',
            method: 'GET',
        }),
        getIfUserIsAdmin: builder.query({
            query: () => '/user/is_admin',
            method: 'GET',
        }),
        getAllTabsUserHasAccessTo: builder.query({
            query: () => '/user/tabs',
            method: 'GET',
        }),
        getUserType: builder.query({
            query: () => '/user/type',
            method: 'GET',
        }),
    }),
});

export const { useGetUserQuery, useGetIfUserIsAdminQuery, useGetAllTabsUserHasAccessToQuery, useGetUserTypeQuery } = userApi;

export default userApi.reducer;