import { useGetUserQuery } from "../redux/api/user";
import { useEffect, useState } from "react";
import { RobotOutlined } from "@ant-design/icons";

const UserDetailsHeader = () => {
    const data = useGetUserQuery();
    const [user, setUser] = useState(null);

    useEffect(() => {
        console.log(data)
        if (data.isSuccess) {
            console.log(data.data)
            setUser(data.data);
        }
    }
        , [data]);

    return (
        <div style={{
            'color': 'white',
            'display': 'flex',
            'marginLeft': '20.5vw',
            'marginTop': '25vh',
        }}>
            {user ? <div>
            <h1 style={{
                    'color': 'white',
                    'fontSize': '2.5rem',
                }}>Hey, {user.given_name} {user.family_name}!</h1>
                <h1 style={{
                    'color': 'white',
                    'fontSize': '2.5rem',
                }}>Explore data, deliver insights,</h1>
                <h1 style={{
                    'color': 'white',
                    'fontSize': '2.5rem',
                }}>Make it happen and <a style={{
                        'color': '#01b375',
                }}>transform growth</a></h1>

                    </div> : <></>}
        </div>
    );
}

export default UserDetailsHeader;