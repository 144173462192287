import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from "./baseQuery";
import { json } from 'react-router-dom';

export const adsReportingApi = createApi({
    reducerPath: 'adsReportingApi',
    baseQuery: baseQuery,
    tagTypes: ['AdsReporting', 'ConversionsTable'],
    endpoints: (builder) => ({
        getClientDropdowns: builder.query({
            query: () => 'clients',
        }),
        getTable: builder.query({
            query: ({ client, startDate, endDate }) => 'google_ads_table?client=' + client + '&start_date=' + startDate + '&end_date=' + endDate,
        }),
        getChart: builder.query({
            query: ({ client, startDate, endDate }) => 'google_ads_chart?client=' + client + '&start_date=' + startDate + '&end_date=' + endDate,
        }),
        getRoiChart: builder.query({
            query: ({ client, startDate, endDate }) => 'google_ads_customer_chart?client=' + client + '&start_date=' + startDate + '&end_date=' + endDate,
        }),
        getCampaignsTable: builder.query({
            query: ({ client, startDate, endDate, metric, allowedCampaigns }) => {
                return {
                    url: 'google_ads_campaigns_table',
                    method: 'POST',
                    body: {
                        'client': client,
                        'start_date': startDate,
                        'end_date': endDate,
                        'metric': metric,
                        'allowed_campaigns': allowedCampaigns,
                    }
                }
            },
        }),
        getAdGroupsTable: builder.query({
            query: ({ client, startDate, endDate, metric, allowedCampaigns, allowedAdGroups }) => {
                return {
                    url: 'google_ads_adgroups_table',
                    method: 'POST',
                    body: {
                        'client': client,
                        'start_date': startDate,
                        'end_date': endDate,
                        'metric': metric,
                        'allowed_campaigns': allowedCampaigns,
                        'allowed_adgroups': allowedAdGroups,
                    }
                }
            }
        }),
        getKeywordsTable: builder.query({
            query: ({ client, startDate, endDate, metric, allowedCampaigns, allowedAdGroups, allowedKeywords }) => {
                return {
                    url: 'google_ads_keywords_table',
                    method: 'POST',
                    body: {
                        'client': client,
                        'start_date': startDate,
                        'end_date': endDate,
                        'metric': metric,
                        'allowed_campaigns': allowedCampaigns,
                        'allowed_adgroups': allowedAdGroups,
                        'allowed_keywords': allowedKeywords,
                    }
                }
            }
        }),
        getUniqueCampaignOptions: builder.query({
            query: ({ client, startDate, endDate }) => 'google_ads_unique_campaign_adgroup_keywords?client=' + client + '&start_date=' + startDate + '&end_date=' + endDate,
        }),
        getCampaignConversionsTable: builder.query({
            query: ({ client, startDate, endDate, campaignName, platform }) => '/paid_media_generic/campaign_conversions_table?client=' + client + '&start_date=' + startDate + '&end_date=' + endDate + '&campaign_name=' + campaignName + '&platform=' + platform,
            providesTags: ['ConversionsTable'],
        }),
        updateCampaignConversionOption: builder.mutation({
            query: ({ client, campaignName, conversionAction, platform }) => {
                return {
                    url: '/paid_media_generic/update_campaign_conversion_option?client=' + client + '&campaign_name=' + campaignName + '&conversion_action=' + conversionAction + '&platform=' + platform,
                    method: 'POST'
                }
            },
            invalidatesTags: ['ConversionsTable'],
        }),
        getUniqueCampaignsPlatform: builder.query({
            query: ({ client, startDate, endDate, platform }) => '/paid_media_generic/unique_campaigns?client=' + client + '&start_date=' + startDate + '&end_date=' + endDate + '&platform=' + platform,
            providesTags: ['ConversionsTable'],
        }),
        getConversionActionsForACampaignPlatform: builder.query({
            query: ({ client, startDate, endDate, campaignName, platform }) => '/paid_media_generic/conversion_actions_for_a_campaign?client=' + client + '&start_date=' + startDate + '&end_date=' + endDate + '&campaign_name=' + campaignName + '&platform=' + platform,
        }),
        getUnsetCampaignOptionsPlatform: builder.query({
            query: ({ client, startDate, endDate, platform }) => '/paid_media_generic/get_unset_campaign_conversion_options?client=' + client + '&start_date=' + startDate + '&end_date=' + endDate + '&platform=' + platform,
            providesTags: ['ConversionsTable'],
        }),
        getSpendBreakdownPlatform: builder.query({
            query: ({ client, startDate, endDate, selectedCampaigns, platform, granularity }) => {
                return {
                    url: '/paid_media_generic/spend_breakdown_chart',
                    method: 'POST',
                    body: {
                        'client': client,
                        'start_date': startDate,
                        'end_date': endDate,
                        'selected_campaigns': selectedCampaigns,
                        'platform': platform,
                        'granularity': granularity
                    }
                }
            },
            providesTags: ['ConversionsTable'],
        }),
        getClicksCpsBreakdownPlatform: builder.query({
            query: ({ client, startDate, endDate, selectedCampaigns, granularity, platform }) => {
                return {
                    url: '/paid_media_generic/clicks_vs_cpc_chart',
                    method: 'POST',
                    body: {
                        'client': client,
                        'start_date': startDate,
                        'end_date': endDate,
                        'selected_campaigns': selectedCampaigns,
                        'granularity': granularity,
                        'platform': platform
                    }
                }
            },
            providesTags: ['ConversionsTable'],
        }),
        getConversionsCpaBreakdownPlatform: builder.query({
            query: ({ client, startDate, endDate, selectedCampaigns, granularity, platform }) => {
                return {
                    url: '/paid_media_generic/conversions_vs_cpa_chart',
                    method: 'POST',
                    body: {
                        'client': client,
                        'start_date': startDate,
                        'end_date': endDate,
                        'selected_campaigns': selectedCampaigns,
                        'granularity': granularity,
                        'platform': platform
                    }
                }
            },
            providesTags: ['ConversionsTable'],
        }),
        conversionsCpaBreakdownTablePlatform: builder.query({
            query: ({ client, startDate, endDate, selectedCampaigns, granularity, platform }) => {
                return {
                    url: '/paid_media_generic/conversions_vs_cpa_table',
                    method: 'POST',
                    body: {
                        'client': client,
                        'start_date': startDate,
                        'end_date': endDate,
                        'selected_campaigns': selectedCampaigns,
                        'granularity': granularity,
                        'platform': platform
                    }
                }
            },
            providesTags: ['ConversionsTable'],
        }),
        getCampaignComparisonPlot: builder.query({
            query: ({ client, startDate, endDate, selectedCampaigns, platform, granularity, metric }) => {
                return {
                    url: '/paid_media_generic/campaign_comparison_plot',
                    method: 'POST',
                    body: {
                        'client': client,
                        'start_date': startDate,
                        'end_date': endDate,
                        'selected_campaigns': selectedCampaigns,
                        'platform': platform,
                        'granularity': granularity,
                        'metric': metric
                    }
                }
            },
        }),
        getCampaignPeriodOnPeriodTable: builder.query({
            query: ({ client, startDate, endDate, compareStartDate, compareEndDate, platform, granularity, metric, selectedCampaigns }) => {
                return {
                    url: '/paid_media_generic/period_on_period_table',
                    method: 'POST',
                    body: {
                        'client': client,
                        'start_date': startDate,
                        'end_date': endDate,
                        'compare_start_date': compareStartDate,
                        'compare_end_date': compareEndDate,
                        'platform': platform,
                        'granularity': granularity,
                        'metric': metric,
                        'selected_campaigns': selectedCampaigns
                    }
                }
            },
        }),
    }),
});

export const {
    useGetClientDropdownsQuery, useGetTableQuery, useGetChartQuery, useGetRoiChartQuery,
    useGetCampaignsTableQuery, useGetAdGroupsTableQuery, useGetKeywordsTableQuery, useGetUniqueCampaignOptionsQuery,
    useGetCampaignConversionsTableQuery, useUpdateCampaignConversionOptionMutation,
    useGetUniqueCampaignsPlatformQuery, useGetConversionActionsForACampaignPlatformQuery,
    useGetUnsetCampaignOptionsPlatformQuery, useGetSpendBreakdownPlatformQuery, useGetClicksCpsBreakdownPlatformQuery,
    useGetConversionsCpaBreakdownPlatformQuery, useConversionsCpaBreakdownTablePlatformQuery,
    useGetCampaignComparisonPlotQuery, useGetCampaignPeriodOnPeriodTableQuery
} = adsReportingApi;

export default adsReportingApi.reducer;