import React from "react";
import { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import { useDispatch, useSelector } from 'react-redux';

import { useGetClientDropdownsQuery } from "../redux/api/adsReporting"
import { setStartAndEndDate, setSelectedClient } from "../redux/store/genericReportingSlice";
import '../containers/containerStyles.css';

import TooltipContainer from "../components/UI/TooltipContainer";

import { useCookies } from "react-cookie";

export const GenericReportingDropdowns = ({
    includeDateRange = true,
    extraDropdowns = []
}) => {

    const dispatch = useDispatch();
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);

    const [cookies, setCookie] = useCookies(['selectedClient']);

    const rangePresets = [
        {
            label: 'Last 7 days',
            value: [dayjs().add(-8, 'd'), dayjs().add(-1, 'd')]
        },
        {
            label: 'Last 30 days',
            value: [dayjs().add(-31, 'd'), dayjs().add(-1, 'd')]
        },
        {
            label: 'Last 90 days',
            value: [dayjs().add(-91, 'd'), dayjs().add(-1, 'd')]
        },
        {
            label: 'Month to date',
            value: [dayjs().startOf('month'), dayjs().add(-1, 'd')]
        },
        {
            label: 'Year to date',
            value: [dayjs().startOf('year'), dayjs().add(-1, 'd')]
        }
    ]

    console.log('query')
    console.log(useGetClientDropdownsQuery)
    const clientDropdownData = useGetClientDropdownsQuery();
    const [clientDropdowns, setClientDropdowns] = useState([]);

    useEffect(() => {
        console.log(clientDropdownData)
        if (clientDropdownData.isSuccess) {
            setClientDropdowns(clientDropdownData.data);
            if (cookies.selectedClient) {
                dispatch(setSelectedClient(cookies.selectedClient));
            }
            else {
                dispatch(setSelectedClient(clientDropdownData.data[0]));
            }
        }
    }
        , [clientDropdownData]);


    function onChange(value) {
        dispatch(setSelectedClient(value));
        setCookie('selectedClient', value, { path: '/' });
    }

    useEffect(() => {
        dispatch(setStartAndEndDate([dayjs().subtract(1, 'month').subtract(1, 'day').format('YYYY-MM-DD'), dayjs().subtract(1, 'day').format('YYYY-MM-DD')]));
    }, []);

    return (
        <div>
        <div style={{
            'height': 'auto',
            'borderRadius': '10px',
            'paddingBottom': '1.5rem',
            'paddingTop': '0.2rem',
            'display': 'flex',
            'justifyContent': 'space-around',
            'alignItems': 'left',
            'alignContent': 'left',
            'alignSelf': 'left',
            
        }}>
            <div style={{
                marginLeft: '1.5vw',
            }}>
        <div style={{
            'display': 'flex',
            'flexDirection': 'row',
        }}>
            <div>
                {clientDropdownData.isLoading ? <Spin></Spin> :
                    <div className='dark-dropdown-container'>
                    <TooltipContainer style={{
                        'alignSelf': 'flex-end',
                        'margin': '0',
                        'width': '0.8vw',
                        'height': '1.0vh',
                    }} invertColor={true} title='Account'></TooltipContainer>
                    <h3 style={{
                                'color': 'white'
                    }}>Account</h3>
                        <Select
                            options={clientDropdowns.map((client) => {
                                return { label: client, value: client }
                            }
                            )}
                            value={selectedClient}
                                    style={{
                                        width: '10vw',

                                    }}
                            dropdownStyle={{
                                'backgroundColor': '#F5F0E6',
                                    }}
                            placeholder='Select an account'
                            onChange={onChange}
                                    ></Select>
                    </div>
                }
                    </div>
                        {includeDateRange && <div className="dark-dropdown-container" style={{
                        width: '15vw',
                        }}>
                        <TooltipContainer style={{
                            'alignSelf': 'flex-end',
                            'margin': '0',
                            'width': '0.8vw',
                            'height': '1.0vh',
                        }} invertColor={true} title='Date Range'></TooltipContainer>
                        <h3 style={{
                            'color': 'white'
                        }}>Date Range</h3>
                        <DatePicker.RangePicker
                            presets={rangePresets}
                            value={[dayjs(selectedStartDate), dayjs(selectedEndDate)]}
                            format='YYYY-MM-DD'
                            onChange={(dates, dateStrings) => {
                                dispatch(setStartAndEndDate([dateStrings[0], dateStrings[1]]));
                            }}
                        >
                        </DatePicker.RangePicker>
                    </div>
                    }
                    {extraDropdowns}
                </div>
            </div>
            </div>
        </div>
    )
}