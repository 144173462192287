import { Switch } from "antd";

export default function IsRollingSwitch({ isRolling, setIsRolling }) {
  return (
    <div style={{
            'display': 'flex',
            'justifyContent': 'center',
            'alignItems': 'center',
            'marginTop': '1vh',
            'marginLeft': '0.5vw',
            'backgroundColor': '#ECE6D9',
            'width': '10vw',
            'height': '6vh',
            'borderRadius': '10px 10px 0px 0px',
            'marginBottom': '0vh'
            
        }}>
          <Switch
            checked={isRolling}
              onChange={(checked) => setIsRolling(checked)}
              checkedChildren="Rolling"
              unCheckedChildren="Not Rolling"
            />
    </div>
  );
}