import React from 'react';
import { Link } from 'react-router-dom';
import UserDetailsHeader from '../containers/UserDetailsHeader';
import { CompassOutlined, StockOutlined, GoogleOutlined, TeamOutlined } from '@ant-design/icons';
import style from './Routing.css';
import '../containers/containerStyles.css'
import '../static/img/big_logo_green.png'

const HomePage = () => {

//     /*
// * Created with https://www.css-gradient.com
// * Gradient link: https://www.css-gradient.com/?c1=43ff01&c2=010101&gt=r&gd=dbr
// */
//     background: #43FF01;
//     background: radial - gradient(at right bottom, #43FF01, #010101);

    return (
        <div style={{
            'width': '100vw',
            'height': '100vh',
            'position': 'fixed',
            'top': '0',
            'left': '0',
            'backgroundColor': '#011402',
            'color': 'white',
            // add radial
            'background': 'radial-gradient(circle at 100% 100%, rgba(1,179,117,1) 0%, rgba(1,20,2,1) 35%)',
        }}>
            <UserDetailsHeader />
            <div style={{
                'display': 'flex',
                'justifyContent': 'center',
                'alignItems': 'center',
            }}>
            </div>
        </div>
    );
};

export default HomePage;