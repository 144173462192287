import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createApi } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_AUTH_API_URL,
    prepareHeaders: (headers, { getState }) => {
        console.log(process.env.REACT_APP_API_URL)
        const token = getState().oauth.jwt_token;
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});


export const claimsApi = createApi({
    baseQuery: baseQuery,
    reducerPath: 'claimsApi',
    endpoints: (builder) => ({
        getMyClaims: builder.query({
            query: () => `my_claims?app_name=${process.env.REACT_APP_APP_NAME}`,
        }),
    })
});


export const { useGetMyClaimsQuery } = claimsApi;
export default claimsApi.reducer;