import React, { useEffect, useState } from 'react';


import { useSelector } from 'react-redux';
import PlotContainer from './PlotContainer';
import { Dropdown, Select } from 'antd';
import { useGetCampaignsTableQuery, useGetAdGroupsTableQuery, useGetKeywordsTableQuery, useGetUniqueCampaignOptionsQuery } from '../redux/api/adsReporting';

const GoogleAdsRankings = () => {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const campaignOptions = useGetUniqueCampaignOptionsQuery({ client: selectedClient, startDate: selectedStartDate, endDate: selectedEndDate });

    const [campaigns, setCampaigns] = useState([]);
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [adgroups, setAdgroups] = useState([]);
    const [selectedAdgroups, setSelectedAdgroups] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);


    useEffect(() => {
        if (campaignOptions.data) {
            setCampaigns(campaignOptions.data.campaign);
            setSelectedCampaigns(campaignOptions.data.campaign);
            setAdgroups(campaignOptions.data.adgroups);
            setSelectedAdgroups(campaignOptions.data.adgroups);
            setKeywords(campaignOptions.data.keywords);
            setSelectedKeywords(campaignOptions.data.keywords);
        }
    }
        , [campaignOptions]);

    const metricOptions = [
        'clicks',
        'impressions',
        'cost',
        'conversions',
        'interactions',
        'cpc',
        'ctr',
        'cpm',
    ];

    const [selectedMetric, setSelectedMetric] = React.useState('clicks');

    return (
        <div>
            <h2>Rankings</h2>
            <h3>Please select a metric.</h3>
            <Select style={{ width: 120 }} value={selectedMetric} onChange={setSelectedMetric}>
                {metricOptions.map((metric) => (
                    <Select.Option key={metric} value={metric}>
                        {metric}
                    </Select.Option>
                ))}
            </Select>
            <br></br>
            <div>
                <h3>Please select campaigns. </h3>
                <p>This will filter every table including campaigns.</p>
                <Select mode="multiple" style={{ width: '100%' }} placeholder="Please select" value={selectedCampaigns} onChange={setSelectedCampaigns} maxTagCount={3} style={
                    {
                        'width': '40%',
                    }
                }>
                    {campaigns.map((campaign) => (
                        <Select.Option key={campaign} value={campaign}>
                            {campaign}
                        </Select.Option>
                    ))}
                </Select>
                <br></br>
                <PlotContainer useApi={useGetCampaignsTableQuery} useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    metric: selectedMetric,
                    allowedCampaigns: selectedCampaigns
                }} ></PlotContainer>
            </div>
            <div>
                <h3>Please select adgroups.</h3>
                <p>This will filter the adgroups and the keyword tables as there can be campaigns without adgroups.</p>
                <Select mode="multiple" style={{ width: '100%' }} placeholder="Please select" value={selectedAdgroups} onChange={setSelectedAdgroups} maxTagCount={3} style={{
                    'width': '40%',
                }}>
                    {adgroups.map((adgroup) => (
                        <Select.Option key={adgroup} value={adgroup}>
                            {adgroup}
                        </Select.Option>
                    ))}
                </Select>
                <br></br>
                <PlotContainer useApi={useGetAdGroupsTableQuery} useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    metric: selectedMetric,
                    allowedCampaigns: selectedCampaigns,
                    allowedAdGroups: selectedAdgroups
                }} ></PlotContainer>
            </div>

            <div>
                <h3>Please select keywords.</h3>
                <p>This will filter the keyword table.</p>
                <Select mode="multiple" style={{ width: '100%' }} placeholder="Please select" value={selectedKeywords} onChange={setSelectedKeywords} maxTagCount={3} style={{
                    'width': '40%',
                }}>
                    {keywords.map((keyword) => (
                        <Select.Option key={keyword} value={keyword}>
                            {keyword}
                        </Select.Option>
                    ))}
                </Select>
                <br></br>
                <PlotContainer useApi={useGetKeywordsTableQuery} useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    metric: selectedMetric,
                    allowedCampaigns: selectedCampaigns,
                    allowedAdGroups: selectedAdgroups,
                    allowedKeywords: selectedKeywords
                }} ></PlotContainer>
            </div>
        </div>
    );
};

export default GoogleAdsRankings;