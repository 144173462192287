import React from 'react';
import { Select, Tabs, Input } from 'antd';
import { GenericReportingDropdowns } from '../../containers/GenericReportingDropdowns';
import ExecutiveSummaryBoxMetrics from '../../containers/ExecutiveSummary/ExecutiveSummaryBoxMetrics';
import ExecutiveSummaryCharts from '../../containers/ExecutiveSummary/ExecutiveSummaryCharts';
import DateGranularityDropdown from '../UI/DateGranularityDropdown';
import { useState } from 'react';
import TooltipContainer from '../UI/TooltipContainer';

function ExecutiveSummaryPage() {

    const [rollingWindow, setRollingWindow] = useState(7);

    const tabs = [
        {
            label: 'Executive Summary',
            key: 'Executive Summary',
            children: [<div>
                <GenericReportingDropdowns
                    includeDateRange={true}
                    extraDropdowns={[
                    <div className='dark-dropdown-container'>
                    <TooltipContainer style={{
                        'alignSelf': 'flex-end',
                        'margin': '0',
                        'width': '0.8vw',
                        'height': '1.0vh',
                            }} invertColor={true} title='Filter By Date Granularity'></TooltipContainer>
                    <h3 style={{
                                'color': 'white'
                    }}>Filter By Date Granularity</h3>
                    <DateGranularityDropdown />
                    </div>,
                    <div className='dark-dropdown-container'>
                        <TooltipContainer style={{
                            'alignSelf': 'flex-end',
                            'margin': '0',
                            'width': '0.8vw',
                            'height': '1.0vh',
                        }} invertColor={true} title='Change the Rolling Window (Days)'></TooltipContainer>
                        <h3 style={{
                            'color': 'white'
                        }}>Change the Rolling Window (days)</h3>
                        <Input type='number' style={{
                            'width': '200px',
                            'marginLeft': '0.2vw'
                        }} value={rollingWindow} onChange={(e) => {
                            setRollingWindow(e.target.value);
                        }} />
                    </div>
                    ]}
                ></GenericReportingDropdowns>
                <br></br>
                <ExecutiveSummaryBoxMetrics></ExecutiveSummaryBoxMetrics>
                <ExecutiveSummaryCharts rollingWindow={rollingWindow}></ExecutiveSummaryCharts>
            </div>]
        }
    ]

    return (
        <div style={{
            'display': 'flex',
            'flexDirection': 'column',
            'justifyContent': 'center',
            'width': '98vw',
            'alignSelf': 'center',
            'marginLeft': '69.2vw',
        }}>
            <Tabs style={{
                'width': '95vw'

            }} items={tabs} ></Tabs>
        </div>
    );
}

export default ExecutiveSummaryPage;