import react, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import PlotContainer from '../containers/PlotContainer';
import {
    useGetTotalSalesChartQuery, useGetRollingPerformanceChartQuery,
    useGetSubsChartQuery, useGetLtrTableQuery, useGetMoMTableQuery
} from '../redux/api/shopifyReporting';
import TableContainer from './TableContainer';

const ShopifyReportingCharts = () => {
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);

    return (
        <div style={{
        }}>
            <div>
            <TableContainer useApi={useGetMoMTableQuery} apiParams={{
                client: selectedClient,
                endDate: selectedEndDate
                }} />
            <br></br>
            </div>
            <div style={{
                'width': '90vw',
                'padding': '1rem',
                'marginBottom': '1rem',
                'borderRadius': '5px',
            }}>
            <PlotContainer useApi={useGetTotalSalesChartQuery} useApiArgs={{
                client: selectedClient,
                startDate: selectedStartDate,
                endDate: selectedEndDate
                }} />
            </div>
            <div style={{
                'display': 'flex',
                'flexDirection': 'row',
                'justifyContent': 'center',
            }}>
            <div style={{
                'width': '55vw',
                'height': '50vh',
                'padding': '1rem',
                'marginRight': '1rem',
                    'borderRadius': '5px',
                }}>
                    <PlotContainer useApi={useGetSubsChartQuery} useApiArgs={{
                        client: selectedClient,
                        startDate: selectedStartDate,
                        endDate: selectedEndDate
                    }} />
            </div>
            <div style={{
                'width': '50vw',
                'height': '50vh',
                    'padding': '1rem',
                    'borderRadius': '5px',

            }}>
            <PlotContainer useApi={useGetRollingPerformanceChartQuery} useApiArgs={{
                client: selectedClient,
                startDate: selectedStartDate,
                endDate: selectedEndDate
                }} />
                </div>
            </div>
        </div>
    )
}

export default ShopifyReportingCharts;
