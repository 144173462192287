import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { Menu } from "antd";
import { useGetUserTypeQuery } from "../../redux/api/user";

export default function LoginIcon() {

    const [cookies, setCookie, removeCookie] = useCookies(['jwt']);
    const [decodedJwt, setDecodedJwt] = useState(null);

    const userType = useGetUserTypeQuery();

    useEffect(() => {
        console.log('user type is')
        console.log(userType)
        if (userType.isError) {
            userType.refetch();
        }
    }, [userType])

    useEffect(() => {
        if (cookies.jwt) {
            const jwtToken = cookies.jwt
            const jwtData = jwtDecode(jwtToken)
            setDecodedJwt(jwtData)
        }
    }, [cookies])

    return (
        <div style={{
            marginTop: '1.8vh',
            marginBottom: '1.8vh',
        }}>
            <div style={{
                    color: 'white',
            }}>

                {decodedJwt ? <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'right',
                    alignItems: 'center',
                    color: 'white',
                    width: '15vw',
                    alignSelf: 'center',
                    marginTop: '0.4vh',
                }}> 
                    <Menu id='menu' mode="horizontal" style={{
                        'display': 'flex',
                        'justifyContent': 'space-around',
                        'alignItems': 'center',
                        'backgroundColor': 'transparent',
                        'width': '15rem',
                    }}>
                        <Menu.SubMenu key="1" title={
                    <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: 'white',
                                alignSelf: 'center',
                    }}>
                    {decodedJwt.name}
                                <img style={{
                                    height: '2.4rem',
                                    marginTop: '0.1rem',
                                    marginBottom: '0.1rem',
                                    borderRadius: '50%',
                                    marginLeft: '0.5vw',
                                    // make the corners round
                                }} src={decodedJwt.picture} alt={''}></img>
                        </div>
                        }>
                            <Menu.Item key="1">
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'white',
                                    alignSelf: 'center',
                                }}>Account Type: {userType.data}</div>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <a onClick={
                                    () => {
                                        removeCookie('jwt');
                                        window.location.reload();
                                    }
                                }>Logout</a>
                            </Menu.Item>
                        </Menu.SubMenu>
                    </Menu>
                </div> : ''}
            </div>
        </div>
    );
}