import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

export const shopifyReportingApi = createApi({
    reducerPath: 'shopifyReportingApi',
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getClientDropdowns: builder.query({
            query: () => 'shopify_clients',
        }),
        getTotalSalesChart: builder.query({
            query: ({ client, startDate, endDate }) => 'shopify_total_sales?client=' + client + '&start_date=' + startDate + '&end_date=' + endDate,
        }),
        getRollingPerformanceChart: builder.query({
            query: ({ client, startDate, endDate }) => 'shopify_rolling_performance?client=' + client + '&start_date=' + startDate + '&end_date=' + endDate,
        }),
        getSubsChart: builder.query({
            query: ({ client, startDate, endDate }) => 'shopify_new_subscribers?client=' + client + '&start_date=' + startDate + '&end_date=' + endDate,
        }),
        getLtrTable: builder.query({
            query: ({ client }) => 'shopify_ltr?client=' + client,
        }),
        getMoMTable: builder.query({
            query({ client, endDate }) {
                return {
                    url: '/shopify_yoy_mom_data',
                    method: 'GET',
                    params: {
                        client: client,
                        end_date: endDate,
                    },
                };
            }
        }),
    }),
});

export const { useGetClientDropdownsQuery, useGetTotalSalesChartQuery,
    useGetRollingPerformanceChartQuery, useGetSubsChartQuery, useGetLtrTableQuery, useGetMoMTableQuery } = shopifyReportingApi;
export default shopifyReportingApi.reducer;