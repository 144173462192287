import './Layout.css'
import Header from './Header'
import { ConfigProvider } from 'antd'

export default function Layout({ children }) {
    return (
        <>
            <ConfigProvider theme={{
                token: {
                    colorBgContainer: '#F5F0E6',
                    colorBg: '#F5F0E6',
                    colorPrimary: '#011402',
                    colorSecondary: '#F5F0E6',
                    colorLink: '#011402',
                    colorText: '#011402',
                    colorTextLight: '#F5F0E6',
                    colorTextDark: '#011402',
                    borderRadius: '10px',
                    fontFamily: 'apoc',
                },
                components: {
                    Tabs: {
                        itemColor: '#011402',
                        inkBarColor: '#011402',
                        itemActiveColor: '#011402',
                        itemHoverColor: 'grey',
                        horizontalItemPadding: '2rem',
                        fontFamily: 'area-inktrap',
                        colorSecondary: '#011402',
                        colorPrimary: '#011402',
                        colorBg: '#F5F0E6',
                    },
                }
            }
            }>
            <Header />
            <div id='content'>
                {children}
                </div >
            </ConfigProvider>
        </>
    )
}