import React from 'react';
import { Select } from 'antd';
import { useState } from 'react';
import PlotContainer from '../PlotContainer';
import { useGetLtrLtvQuery } from '../../redux/api/genericReporting';
import { useSelector } from 'react-redux';
import '../containerStyles.css';
import TooltipContainer from '../../components/UI/TooltipContainer';

const LifetimeRevenue = () => {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);

    const [isLtv, setIsLtv] = useState(false);
    const [filterType, setFilterType] = useState('All');

    return (
        <div>
            <div className='flexible-container' style={{
                'display': 'flex',
                'flexDirection': 'row',
                'justifyContent': 'center',
                'width': '95vw',
                
            }}>
                <div>
                <h2>LTR Vs. LTV <TooltipContainer title='LTR Vs. LTV'></TooltipContainer></h2>
                <Select
                    style={{
                            'width': '10vw',
                            'marginRight': '1vw'
                    }}
                    options={
                        [
                            { label: 'LTR', value: 'ltr' },
                            { label: 'LTV', value: 'ltv' }
                        ]
                    }
                    onChange={
                    (value) => {
                        setIsLtv(value === 'ltv');
                        }
                    }
                    value={isLtv ? 'ltv' : 'ltr'}
                ></Select>
                </div>
            <div>
                    <h2>Filter By <TooltipContainer title='Filter By'></TooltipContainer></h2>
                <Select style={{
                    'width': '10vw'
                }}
                    options={
                        [
                            { label: 'All', value: 'All' },
                            { label: 'OTP', value: 'OTP' },
                            { label: 'Subscription', value: 'Subscription' }
                        ]
                    }
                    onChange={
                        (value) => {
                            setFilterType(value);
                        }
                    }
                    value={filterType}
                ></Select>
                </div>
            </div>
            <div className='flexible-container' style={{
                'width': '95vw',
                'height': '170vh',
            }}>
                <PlotContainer tooltipTitle='Lifetime Revenue' useApi={useGetLtrLtvQuery} useApiArgs={{
                    client: selectedClient,
                    isLtv: isLtv,
                    filterType: filterType
                }} />
            </div>
        </div>
    );
};

export default LifetimeRevenue;