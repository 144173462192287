import { Button, Select } from "antd"
import { useGetUniqueCampaignsPlatformQuery, useGetConversionActionsForACampaignPlatformQuery, useGetCampaignConversionsTableQuery, useGetUnsetCampaignOptionsPlatformQuery, useUpdateCampaignConversionOptionMutation } from "../../redux/api/adsReporting";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TableContainer from "../TableContainer";
import TooltipContainer from "../../components/UI/TooltipContainer";

export default function SettingsDropdowns({platform}) {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);

    const [campaignOptions, setCampaignOptions] = useState([]);
    const [conversionOptions, setConversionOptions] = useState([]);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [selectedConversion, setSelectedConversion] = useState(null);

    const uniqueCampaigns = useGetUniqueCampaignsPlatformQuery({
        client: selectedClient,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        platform: platform
    });

    const uniqueConversionOptions = useGetConversionActionsForACampaignPlatformQuery({
        client: selectedClient,
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        campaignName: selectedCampaign,
        platform: platform
    });

    const unsetCampaignOptions = useGetUnsetCampaignOptionsPlatformQuery(
        {
            client: selectedClient,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            platform: platform
        }
    );

    const [updateCampaignConversionOption] = useUpdateCampaignConversionOptionMutation();

    useEffect(() => {
        if (uniqueCampaigns.status === 'fulfilled') {
            setCampaignOptions(uniqueCampaigns.data.map(
                (campaign) => {
                    return {
                        label: campaign,
                        value: campaign
                    }
                }

            ));
        }
    }, [uniqueCampaigns]);


    useEffect(() => {
        if (uniqueConversionOptions.status === 'fulfilled') {
            setConversionOptions(uniqueConversionOptions.data.map(
                (conversion) => {
                    return {
                        label: conversion,
                        value: conversion
                    }
                }
            ));
        }
        else {
            setConversionOptions([]);
        }
    }, [uniqueConversionOptions]);


    return (
        <div>
            <TooltipContainer title='Settings'></TooltipContainer>
            <div style={{
                'display': 'flex',
                'flexDirection': 'row'
            }}>
                <div style={{
                    marginRight: '2vw'
                }}>
                    <TableContainer useApi={useGetCampaignConversionsTableQuery} apiParams={{
                        client: selectedClient,
                        platform: platform,
                    }} width="28vw" />
                </div>
                <div>
                    <h3>These campaigns do not have a set conversion goal for the time frame selected:</h3>
                    <ul>
                        {unsetCampaignOptions.data ? unsetCampaignOptions.data.map((campaign) => {
                            return <li>{campaign}</li>
                        }) : <></>}
                    </ul>
                </div>
            </div>
            <h2>Campaigns</h2>
            <Select
                showSearch={true}
                style={{ width: '100%' }}
                placeholder="Please select"
                options={campaignOptions}
                onChange={(value) => {
                    setSelectedCampaign(value);
                    setSelectedConversion(null);
                }
                }
            />
            <h2>Conversions</h2>
            <Select
                showSearch={true}
                style={{ width: '100%' }}
                placeholder="Please select"
                options={conversionOptions}
                onChange={(value) => setSelectedConversion(value)}
                value={selectedConversion}
            />
            <Button onClick={
                () => {
                    updateCampaignConversionOption({
                        client: selectedClient,
                        campaignName: selectedCampaign,
                        conversionAction: selectedConversion,
                        platform: platform
                    })
                }
            } style={{
                'marginTop': '1vh'
            }} type='primary'>Submit</Button>
        </div>
    )

}