import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

export const genericReportingApi = createApi({
    reducerPath: 'genericReportingApi',
    baseQuery: baseQuery,
    tagTypes: ['GenericReporting', 'ExecutiveSummary', 'ProductGroupConfig', 'ProductGroups', 'MetricOptions'],
    endpoints: (builder) => ({
        getBoxMetrics: builder.query({
            query: ({
                client,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate
            }) => '/executive_summary/box_metrics' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&compare_start_date=" + compareStartDate + "&compare_end_date=" + compareEndDate,
            providesTags: ['ExecutiveSummary'],
        }),
        getNewCustomerVsNcpa: builder.query({
            query: ({
                client,
                granularity,
                startDate,
                endDate,
                isRolling,
                rollingWindow
            }) => '/executive_summary/new_customers_vs_ncpa_chart' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + '&is_rolling=' + isRolling + '&rolling_window=' + rollingWindow,
            providesTags: ['ExecutiveSummary'],
        }),
        getNewSubsVsCpns: builder.query({
            query: ({
                client,
                granularity,
                startDate,
                endDate,
                isRolling,
                rollingWindow
            }) => '/executive_summary/new_subs_vs_cpns_chart' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
            providesTags: ['ExecutiveSummary'],
        }),
        getRevenueSplitByNewReturningPlot: builder.query({
            query: ({
                client,
                country,
                granularity,
                startDate,
                endDate
            }) => '/executive_summary/revenue_split_by_new_and_returning_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&country=" + country + "&granularity=" + granularity,
            providesTags: ['ExecutiveSummary'],
        }),
        getUniqueShopifyCountries: builder.query({
            query: ({client}) => '/shopify_unique_countries' + "?client=" + client,
            providesTags: ['ExecutiveSummary'],
        }),
        getLtrLtv: builder.query({
            query: ({client, isLtv, filterType}) => '/ltr_ltv' + "?client_name=" + client + "&is_ltv=" + isLtv + "&type=" + filterType,
        }),
        getCohortedRetention: builder.query({
            query: ({client, filterType}) => '/cohorted_retention' + "?client_name=" + client + "&type=" + filterType,
        }),
        getRollingSpend: builder.query({
            query: ({client, startDate, endDate, granularity, rollingWindow}) => '/executive_summary/rolling_spend' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&rolling_window=" + rollingWindow,
            providesTags: ['ExecutiveSummary'],
        }),
        getAovTrend: builder.query({
            query: ({client, startDate, endDate, granularity}) => '/executive_summary/aov_trend' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity,
            providesTags: ['ExecutiveSummary'],
        }),
        createProductGroupConfig: builder.mutation({
            query: ({
                client,
                configName,
            }) => ({
                url: '/settings/product_group_config' + "?client=" + client + "&config_name=" + configName,
                method: 'PUT',
            }),
            invalidatesTags: ['ProductGroupConfig'],
        }),
        deleteProductGroup: builder.mutation({
            query: ({
                client,
                configName,
                productGroupName,
            }) => ({
                url: '/settings/product_group' + "?client=" + client + "&config_name=" + configName + "&product_group_name=" + productGroupName,
                method: 'DELETE',
            }),
            invalidatesTags: ['ProductGroups'],
        }),
        listProductGroupConfigs: builder.query({
            query: ({client}) => '/settings/product_group_configs' + "?client=" + client,
            providesTags: ['ProductGroupConfig'],
        }),
        activateProductGroupConfig: builder.mutation({
            query: ({
                client,
                configName,
            }) => ({
                url: '/settings/activate_product_group_config' + "?client=" + client + "&config_name=" + configName,
                method: 'PUT',
            }),
            invalidatesTags: ['ProductGroupConfig'],
        }),
        getActiveProductGroupConfig: builder.query({
            query: ({client}) => '/settings/active_product_group_config' + "?client=" + client,
            providesTags: ['ProductGroupConfig'],
        }),
        deleteProductGroupConfig: builder.mutation({
            query: ({
                client,
                configName,
            }) => ({
                url: '/settings/product_group_config' + "?client=" + client + "&config_name=" + configName,
                method: 'DELETE',
            }),
            invalidatesTags: ['ProductGroupConfig'],
        }),
        addAProductGroup: builder.mutation({
            query: ({
                client,
                configName,
                productGroup,
            }) => ({
                url: '/settings/product_group' + "?client=" + client + "&config_name=" + configName + "&product_group_name=" + productGroup,
                method: 'PUT',
            }),
            invalidatesTags: ['ProductGroups'],
        }),
        getProductsTable: builder.query({
            query: ({client, configName, startDate, endDate}) => '/settings/product_table' + "?client=" + client + "&config_name=" + configName + "&start_date=" + startDate + "&end_date=" + endDate,
            providesTags: ['ProductGroups'],
        }),
        getProductGroups: builder.query({
            query: ({client, configName}) => '/settings/product_groups' + "?client=" + client + "&config_name=" + configName,
            providesTags: ['ProductGroups'],
        }),
        assignToProductGroup: builder.mutation({
            query: ({
                client,
                configName,
                productGroup,
                products
            }) => ({
                url: '/settings/assign_to_product_group' + "?client=" + client + "&config_name=" + configName + "&product_group_name=" + productGroup,
                method: 'PUT',
                body: {
                    products: products
                }
            }),
            invalidatesTags: ['ProductGroups'],
        }),
        getLtrByCategory: builder.query({
            query: ({ clientName, selectedConfig }) => '/ltr_by_category' + "?client_name=" + clientName + "&selected_config=" + selectedConfig,
        }),
        getNewReturningCustomersByCategory: builder.query({
            query({ clientName, selectedConfig, startDate, endDate, granularity }) {
                return '/executive_summary/categorical_new_returning_customers' + "?client=" + clientName + "&product_group_config_name=" + selectedConfig + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity;
            },
            providesTags: ['ExecutiveSummary'],
        }),
        getKlaviyoAcquisition: builder.query({
            query: ({
                client,
                startDate,
                endDate
            }) => '/klaviyo/acqusition' + "?client_name=" + client + "&start_date=" + startDate + "&end_date=" + endDate,
        }),
        getBoxMetricOptions: builder.query({
            query: ({ client }) => '/settings/box_metric_options' + "?client=" + client,
        }),
        setBoxMetricOptions: builder.mutation({
            query: ({ client, metricTitle, orderNumber }) => ({
                url: '/settings/box_metric_option' + "?client=" + client + "&metric_title=" + metricTitle + "&order_number=" + orderNumber,
                method: 'PUT',
            }),
            invalidatesTags: ['MetricOptions'],
        }),
        deleteBoxMetricOptions: builder.mutation({
            query: ({ client, metricTitle, orderNumber }) => ({
                url: '/settings/box_metric_option' + "?client=" + client + "&metric_title=" + metricTitle + "&order_number=" + orderNumber,
                method: 'DELETE',
            }),
            invalidatesTags: ['MetricOptions'],
        }),
        getBoxMetricOptionsTable: builder.query({
            query: ({ client }) => '/settings/configured_box_metric_options_table' + "?client=" + client,
            providesTags: ['MetricOptions'],
        }),
    }),
});


export const {
    useGetBoxMetricsQuery,
    useGetNewCustomerVsNcpaQuery,
    useGetNewSubsVsCpnsQuery,
    useGetRevenueSplitByNewReturningPlotQuery,
    useGetUniqueShopifyCountriesQuery,
    useGetLtrLtvQuery,
    useGetCohortedRetentionQuery,
    useGetRollingSpendQuery,
    useGetAovTrendQuery,
    useCreateProductGroupConfigMutation,
    useListProductGroupConfigsQuery,
    useActivateProductGroupConfigMutation,
    useGetActiveProductGroupConfigQuery,
    useDeleteProductGroupConfigMutation,
    useAddAProductGroupMutation,
    useGetProductsTableQuery,
    useGetProductGroupsQuery,
    useAssignToProductGroupMutation,
    useGetLtrByCategoryQuery,
    useGetNewReturningCustomersByCategoryQuery,
    useGetKlaviyoAcquisitionQuery,
    useDeleteProductGroupMutation,
    useGetBoxMetricOptionsQuery,
    useSetBoxMetricOptionsMutation,
    useDeleteBoxMetricOptionsMutation,
    useGetBoxMetricOptionsTableQuery,
} = genericReportingApi;

export default genericReportingApi.reducer;