import React from 'react';
import { Button, Modal } from 'antd';
import { useState, useEffect } from 'react';
import { useGetMyClaimsQuery } from '../../redux/api/claims';
import { Form, Input, useForm } from 'antd';
import { useCreateCompassProjectMutation, useGetCompassProjectsQuery, useDeleteCompassProjectMutation, useSetProjectAsActiveMutation } from '../../redux/api/compassProject';
import { setSelectedProject } from '../../redux/store/compassSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {Spin} from 'antd';

function CompassProjects() {

    const myClaims = useGetMyClaimsQuery();
    const [createCompassProject] = useCreateCompassProjectMutation();
    const compassProjects = useGetCompassProjectsQuery();
    const [deleteCompassProject] = useDeleteCompassProjectMutation();
    const [setProjectAsActive] = useSetProjectAsActiveMutation();

    const [amIAdmin, setAmIAdmin] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [projectDivs, setProjectDivs] = useState([]);

    const [isListLoading, setIsListLoading] = useState(false);

    const selectedProject = useSelector((state) => state.compass.selectedProject);
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);

    const dispatch = useDispatch();

    const [form] = Form.useForm();

    useEffect(() => {
        if (myClaims.data) {
            myClaims.data.map((claim) => {
                if (claim.claim_value === 'admin') {
                    setAmIAdmin(true);
                }
            }
            )
        }
    }, [myClaims]);

    useEffect(() => {
        if (compassProjects.data) {
            const newProjectDivs = compassProjects.data.map((project) => {
                if (project.is_active && !selectedProject.project_id) {
                    dispatch(setSelectedProject(project));
                }
                if (project.client_name === selectedClient) {
                    return (
                        <div key={project.id} style={{
                            padding: '0.05rem',
                            margin: '0.5rem',
                            border: '1px',
                            borderRadius: '0.5rem',
                            backgroundColor: '#03FFA8',
                        }}>
                            <div style={{
                                padding: '0.5rem',
                            }}>
                                <h3>Title: {project.project_name}</h3>
                                <p>Client: {project.client_name}</p>
                                <p>Description: {project.project_description}</p>
                                <p>Created By: {project.created_by}</p>
                                <p>{project.created_at}</p>

                                <Button style={{
                                    'marginRight': '0.5rem'
                                }} onClick={
                                    () => {
                                        deleteCompassProject({ id: project.project_id });
                                        setIsListLoading(true);
                                    }
                                }>
                                    Delete
                                </Button>
                                <Button style={{
                                    'marginRight': '0.5rem'
                                }} onClick={
                                    () => {
                                        dispatch(setSelectedProject(project));
                                    }
                                }>
                                    {selectedProject.project_id === project.project_id ? 'Selected' : 'Select'}
                                </Button>
                                <Button disabled={project.is_active} onClick={
                                    () => {
                                        setProjectAsActive({ id: project.project_id, client_name: project.client_name });
                                    }
                                }>
                                    {project.is_active ? 'Active' : 'Set as Active (For Client)'}
                                </Button>
                            </div>
                        </div>
                    );
                }
                });
            setProjectDivs(newProjectDivs);
        }
    }, [compassProjects.data, selectedProject, selectedClient]);

    useEffect(() => {
        if (compassProjects.status == 'fulfilled') {
            setIsListLoading(false);
        }
        else if (compassProjects.status == 'pending') {
            setIsListLoading(true);
        }
        else {
            setIsListLoading(true);
        }
    }
    , [compassProjects]);


    function onCancel() {
        setIsModalOpen(false);
        form.resetFields();
    }

    function onFinish() {
        setIsListLoading(true);
        const values = form.getFieldsValue();
        values['client_name'] = selectedClient;
        console.log(values)
        createCompassProject({
            client_name: values.client_name,
            project_name: values.project_name,
            project_description: values.project_description
        });
        setIsModalOpen(false);
        form.resetFields();
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            {amIAdmin && <Button style={{
            }} onClick={
                () => {
                    setIsModalOpen(true);
                }

            }>
                Create A New Project
            </Button>
            }
            <Modal open={isModalOpen} onCancel={onCancel} onOk={onFinish} >
                <div>
                    <Form form={form}>
                        <Form.Item name="project_name" label="Project Name">
                            <Input />
                        </Form.Item>
                        <Form.Item name="project_description" label="Project Description">
                            <Input />
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <br></br>
            <div style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                border: '1px',
                borderStyle: 'solid',
                borderColor: '#03FFA8',
                borderRadius: '0.5rem',
            }}>
                {isListLoading ? <Spin></Spin> : <>{projectDivs}</> }
            </div>
        </div>
    );
}

export default CompassProjects;