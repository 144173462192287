import { Tabs } from "antd";
import SettingsDropdowns from "../../containers/PaidMedia.js/SettingsDropdowns";
import Reporting from "../../containers/PaidMedia.js/Reporting";

export default function Facebook() {

    const tabs = [
        {
            label: 'Settings',
            key: 'Settings',
            children: [<SettingsDropdowns platform='facebook'></SettingsDropdowns>]
        },
        {
            label: 'Reporting',
            key: 'Reporting',
            children: [<Reporting platform='facebook'></Reporting>]
        }
    ]

    return (
        <div>
            <Tabs style={{
                'width': '95vw',
                'marginLeft': '1vw',
            }} items={tabs} defaultActiveKey='Reporting'></Tabs>
        </div>
    );

};