import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import { userApi } from './api/user'
import combinedApiMiddleware from './middleware'


const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(combinedApiMiddleware),
})


export default store