import React from 'react';
import { Tabs } from 'antd';
import { GenericReportingDropdowns } from '../containers/GenericReportingDropdowns';
import GoogleAnalyticsFunnelingCharts from '../containers/SiteAnalytics/GoogleAnalyticsFunnelingCharts';
import ForwardIndicatorTab from '../containers/SiteAnalytics/ForwardIndicatorTab';
import TooltipContainer from './UI/TooltipContainer';
import { Input, Switch } from 'antd';


function SiteAnalyticsPage() {

    const [isRolling, setIsRolling] = React.useState(false);
    const [rollingWindow, setRollingWindow] = React.useState(7);

    const tabs = [
        {
            label: 'Conversion Funnel Reporting',
            key: 'Conversion Funnel Reporting',
            children: [<GoogleAnalyticsFunnelingCharts
                isRolling={isRolling}
                rollingWindow={rollingWindow}
            ></GoogleAnalyticsFunnelingCharts>]
        },
        {
            label: 'Forward Indicators',
            key: 'Forward Indicators',
            children: [<ForwardIndicatorTab
                isRolling={isRolling}
                rollingWindow={rollingWindow}>
            </ForwardIndicatorTab>]
        }
    ]

    return (
        // JSX code for your component's UI goes here
        <div style={{
            'display': 'flex',
            'flexDirection': 'column',
            'justifyContent': 'center',
            'width': '98vw',
            'alignSelf': 'center',
            'marginLeft': '69.2vw',
        }}>
            <GenericReportingDropdowns
                extraDropdowns={
                    [
                        <div className='dark-dropdown-container'>
                            <TooltipContainer style={{
                                'alignSelf': 'flex-end',
                                'margin': '0',
                                'width': '0.8vw',
                                'height': '1.0vh',
                            }} invertColor={true} title='Toggle Rolling'></TooltipContainer>
                            <h3 style={{
                                'color': 'white'
                            }}>Toggle Rolling</h3>
                            <Switch checkedChildren={<div style={{
                                'color': 'black',
                            }}>Rolling</div>}
                                unCheckedChildren={
                                    <div style={{
                                        'color': 'black',
                                    }}>Not Rolling</div>
                                } style={{
                                    'backgroundColor': '#F5F0E6',
                            }} checked={isRolling} onChange={(checked) => {
                                setIsRolling(checked);
                            }} />
                        </div>,
                        <div className='dark-dropdown-container'>
                            <TooltipContainer style={{
                                'alignSelf': 'flex-end',
                                'margin': '0',
                                'width': '0.8vw',
                                'height': '1.0vh',
                            }} invertColor={true} title='Change the Rolling Window (Days)'></TooltipContainer>
                            <h3 style={{
                                'color': 'white'
                            }}>Change the Rolling Window (days)</h3>
                            <Input type='number' style={{
                                'width': '200px',
                                'marginLeft': '0.2vw'
                            }} value={rollingWindow} onChange={(e) => {
                                setRollingWindow(e.target.value);
                            }} />
                        </div>
                    ]
                }
            ></GenericReportingDropdowns>
            <Tabs style={{
                'width': '95vw',
                'marginLeft': '1vw',
            }} items={tabs} ></Tabs>
        </div>
    );
}

export default SiteAnalyticsPage;