import React from "react";
import { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import { useDispatch, useSelector } from 'react-redux';

import { useGaCountriesQuery, useGaDeviceCategoriesQuery, useGaTop10SourcesQuery, useGaSessionsQuery, useGetCheckIsUsingNewGaTableQuery, useGetTopLandingPagesQuery } from "../../redux/api/gaFunneling"
import { setCountryFilter, setGaDeviceCategoriesFilter, setGaSourceFilter, setGaLandingPageFilter } from "../../redux/store/genericReportingSlice";
import PlotContainer from "../PlotContainer";
import TooltipContainer from "../../components/UI/TooltipContainer";


function GoogleAnalyticsFunnelingDropdowns() {

    const dispatch = useDispatch();
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedCountry = useSelector((state) => state.genericReporting.countryFilter);
    const selectedDeviceCategory = useSelector((state) => state.genericReporting.gaDeviceCategoriesFilter);
    const selectedSource = useSelector((state) => state.genericReporting.gaSourceFilter);
    const selectedLandingPage = useSelector((state) => state.genericReporting.gaLandingPageFilter);

    const isNewTable = useGetCheckIsUsingNewGaTableQuery(selectedClient);

    const gaCountriesData = useGaCountriesQuery(selectedClient);
    const gaDeviceCategoriesData = useGaDeviceCategoriesQuery(selectedClient);
    const gaTop10SourcesData = useGaTop10SourcesQuery(selectedClient);
    const gaTopLandingPagesData = useGetTopLandingPagesQuery(selectedClient);
    
    return (
        <div>
            <div>
                {selectedClient && <div>
                <h2 style={{
                    marginBottom: '0'
                }}>Filters</h2>
                <div style={{
                    'display': 'flex',
                    'flexDirection': 'row',
                    'marginBottom': '0'
                }}>
                {gaCountriesData.data && gaCountriesData.data.length > 0 ? (
                        <div>
                                <h3 style={{
                                    'marginTop': '0.5vh',
                                    'marginBottom': '0.5vh'
                                }}>Country <TooltipContainer title='Country'></TooltipContainer></h3>
                        <Select
                        showSearch={true}
                        value={selectedCountry}
                        options={gaCountriesData.data.map((country) => {
                            return { label: country, value: country };
                        })}
                        style={{ width: '10vw' }}
                        placeholder='Select a country'
                        onChange={(value) => dispatch(setCountryFilter(value))}
                        ></Select>
                        </div>
                ) : (
                    <Spin />
                )}
                {gaDeviceCategoriesData.data && gaDeviceCategoriesData.data.length > 0 ? (
                    <div style={{
                        'marginLeft': '0.5vw'
                    }}>
                                <h3 style={{
                                    'marginTop': '0.5vh',
                                    'marginBottom': '0.5vh'
                                }}>Device Category  <TooltipContainer title='Device Category'></TooltipContainer></h3>
                            <Select
                            showSearch={true}
                            value={selectedDeviceCategory}
                            options={gaDeviceCategoriesData.data.map((deviceCategory) => {
                                return { label: deviceCategory, value: deviceCategory };
                            })}
                            style={{ width: '10vw' }}
                            placeholder='Select a device category'
                            onChange={(value) => dispatch(setGaDeviceCategoriesFilter(value))}
                        ></Select>
                    </div>
                ) : (
                    <Spin />
                    )}
                    {gaTop10SourcesData.data && gaTop10SourcesData.data.length > 0 ? <div style={{
                            'marginLeft': '0.5vw'
                        }}>
                            <h3 style={{
                                'marginTop': '0.5vh',
                                'marginBottom': '0.5vh'
                            }}> Source/Medium <TooltipContainer title='Source/Medium'></TooltipContainer></h3>
                            <Select
                                showSearch={true}
                                value={selectedSource}
                                options={gaTop10SourcesData.data.map((source) => {
                                    return { label: source, value: source };
                                })}
                                style={{ width: '10vw' }}
                                placeholder='Select a source/medium'
                                onChange={(value) => dispatch(setGaSourceFilter(value))}
                            ></Select>
                        </div> : <Spin></Spin>}
                    </div>
                    {isNewTable.data && gaTopLandingPagesData.data ? (
                        <div>
                            <h3 style={{
                                'marginTop': '0.5vh',
                                'marginBottom': '0.5vh'
                            }}>Landing Page <TooltipContainer title='Landing Page'></TooltipContainer></h3>
                            <Select
                                showSearch={true}
                                value={selectedLandingPage}
                                options={gaTopLandingPagesData.data.map((landingPage) => {
                                    return { label: landingPage, value: landingPage };
                                })}
                                style={{ width: '30vw'}}
                                placeholder='Select a landing page'
                                onChange={(value) => dispatch(setGaLandingPageFilter(value))}
                            ></Select>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>}
            </div>
        </div>
    );
}

export default GoogleAnalyticsFunnelingDropdowns;