import { useGetAllTabsUserHasAccessToQuery } from "../../redux/api/user";
import { useEffect, useState } from "react";
import { Tabs } from "antd";
import { GenericReportingDropdowns } from '../../containers/GenericReportingDropdowns';
import ProductGroupingPage from "../../containers/Settings/ProductGroupingPage";
import SettingsDropdowns from "../../containers/PaidMedia.js/SettingsDropdowns";
import BoxMetricSettings from "../../containers/Settings/BoxMetricsSettingsPage";

export default function SettingsPage() {

    const [tabs, setTabs] = useState([]);

    const allowedTabs = useGetAllTabsUserHasAccessToQuery();

    useEffect(() => {
        if (allowedTabs.isError) {
            allowedTabs.refetch()
        }
        if (allowedTabs.isSuccess) {
            var tmpTabs = [];
            for (var i = 0; i <= allowedTabs.data.length; i++) {
                const tab = allowedTabs.data[i];
                if (tab == 'executive_summary') {
                    tmpTabs.push({
                        label: 'Product Grouping',
                        key: 'Product Grouping',
                        children: [<ProductGroupingPage />]
                    });
                    tmpTabs.push({
                        label: 'Box Metrics',
                        key: 'Box Metrics',
                        children: [<BoxMetricSettings />]
                    });
                }
                else if (tab == 'paid_media') {
                    tmpTabs.push({
                        label: 'Google Settings',
                        key: 'Google Settings',
                        children: [<SettingsDropdowns platform='google' />]
                    });
                    tmpTabs.push({
                        label: 'Facebook Settings',
                        key: 'Facebook Settings',
                        children: [<SettingsDropdowns platform='facebook' />]
                    });
                }
            }
            setTabs(tmpTabs);
        }
    },
        [allowedTabs]);

    return (
        <div style={{
            'display': 'flex',
            'flexDirection': 'column',
            'justifyContent': 'center',
            'width': '98vw',
            'alignSelf': 'center',
            'marginLeft': '69.2vw',
        }}>
            <GenericReportingDropdowns></GenericReportingDropdowns>
            <Tabs style={{
                'width': '95vw',
                'marginLeft': '1vw',
            }} items={tabs} ></Tabs>
        </div>
    );
}