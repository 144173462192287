import './Header.css'
import logo from '../../static/img/creamlogo.png'
import dashboardlogo from '../../static/img/dashboardlogo.png'
import React, { useState, useEffect } from 'react'
import { CompassOutlined, } from '@ant-design/icons'
import { Menu } from 'antd'
import { useGetAllTabsUserHasAccessToQuery } from '../../redux/api/user'
import compasslogo from '../../static/img/compasslogo.png'
import LoginIcon from './LoginIcon'
import TooltipContainer from './TooltipContainer'

export default function Header() {

    const currentUrlPath = window.location.pathname

    const [menuOpen, setMenuOpen] = useState(false);
    const allowedTabs = useGetAllTabsUserHasAccessToQuery();

    const [dashboards, setDashboards] = useState([]);
    const [tabsInline, setTabs] = useState([]);

    const [selectedKeys, setSelectedKeys] = useState([currentUrlPath]);

    useEffect(() => {
        console.log(allowedTabs)
        if (allowedTabs.isSuccess) {
            var tabs = allowedTabs.data;
            tabs = [...new Set(tabs)];
            setDashboards([])
            setTabs([])
            var tmpDashboards = [];
            var tmpTabs = [];
            for (let i = 0; i < tabs.length; i++) {
                const tab = tabs[i];
                console.log('tab');
                console.log(tab);
                console.log(tabs);
                if (tab == 'executive_summary') {
                    console.log('executive_summary')
                    tmpDashboards.push(<Menu.Item key="/executive-summary"><a href='/executive-summary'>Executive Summary</a></Menu.Item>);
                } else if (tab == 'site_analytics') {
                    console.log('site_analytics')
                    tmpDashboards.push(<Menu.Item key="/site-analytics"><a href='/site-analytics'>Site Analytics</a></Menu.Item>);
                } else if (tab == 'marketing_reporting') {
                    tmpDashboards.push(<Menu.Item key="/marketing-reporting"><a href='/marketing-reporting'>Marketing Reporting</a></Menu.Item>);
                } else if (tab == 'compass') {
                    tmpTabs.push(<Menu.Item key="/compass"><a href='/compass'>Compass</a></Menu.Item>);
                } else if (tab == 'ltr') {
                    tmpDashboards.push(<Menu.Item key="/customer-analytics"><a href='/customer-analytics'>Customer Analytics</a></Menu.Item>);
                } else if (tab == 'paid_media') {
                    tmpDashboards.push(<Menu.Item key="/paid-media"><a href='/paid-media'>Paid Media</a></Menu.Item>);
                }
                else if (tab == 'klaviyo') {
                    tmpDashboards.push(<Menu.Item key="/klaviyo-acquisition"><a href='/klaviyo-acquisition'>Klaviyo Acquisition</a></Menu.Item>);
                }
            }
            console.log('setting')
            console.log(tmpDashboards)
            setDashboards(tmpDashboards);
            setTabs(tmpTabs);
        }
        if (allowedTabs.isError) {
            allowedTabs.refetch()
        }
    }
        , [allowedTabs]);

    const handleMenuClick = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div id='headerdiv'>
            <div style={{
                'display': 'flex',
                'width': '100%'
            }}>
            <a href='/'>
                <img style={{
                    marginLeft: '1vw',
                    marginTop: '2.8vh',
                    marginRight: '1vw',
                    width: '20vw',
                    height: 'auto',
                    alignContent: 'left',
                    alignSelf: 'left',
                    alignItems: 'left',
                }} id='logoimage' src={logo} alt="loading..." />
            </a>
            <div id='headerdiv-inside'>
            {allowedTabs.data ? 
            <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: 'auto',
                        minWidth: '40vw',
                        border: '1px solid #01b375',
                        borderRadius: '10px',
                        marginTop: '1.8vh',
                        marginBottom: '1.8vh',
                        paddingLeft: '0.0vw',
                        paddingRight: '1.8vw',
                        marginRight: '1.5vw',
            }}>
            <Menu id='menu' mode="horizontal" selectedKeys={[currentUrlPath]} style={{
                'display': 'flex',
                'justifyContent': 'space-around',
                'alignItems': 'center',
                'backgroundColor': 'transparent',
                'width': '100%',
            }}>
            <Menu.SubMenu title="Growth Dashboards">
                    {dashboards}
            </Menu.SubMenu>
            {tabsInline}
            <Menu.Item key="/settings">
                <a href='/settings'>Settings</a>
            </Menu.Item>
                        </Menu>
                            <LoginIcon></LoginIcon>
                    </div> : <div></div>}
                </div>
            </div>
        </div>
        )
    }