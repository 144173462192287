import React from 'react';
import { Tabs } from 'antd';
import { GenericReportingDropdowns } from '../../containers/GenericReportingDropdowns';
import SettingsDropdowns from '../../containers/PaidMedia.js/SettingsDropdowns';
import Reporting from '../../containers/PaidMedia.js/Reporting';

function Google() {

    const tabs = [
        {
            label: 'Settings',
            key: 'Settings',
            children: [<SettingsDropdowns platform='google'></SettingsDropdowns>]
        },
        {
            label: 'Reporting',
            key: 'Reporting',
            children: [<Reporting platform='google'></Reporting>]
        }
    ]

    return (
        // JSX code for your component's UI goes here
        <div>
            <Tabs style={{
                'width': '95vw',
                'marginLeft': '1vw',
            }} items={tabs} defaultActiveKey='Reporting' ></Tabs>
        </div>
    );
}

export default Google;