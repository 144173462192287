import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    jwt_token: ""
}

export const oauthSlice = createSlice({
    name: 'oauth',
    initialState,
    reducers: {
        setJwtToken: (state, value) => {
            state.jwt_token = value.payload;
        },
        expireJwtToken: (state) => {
            state.jwt_token = "";
        }
    }
})

export const { setJwtToken } = oauthSlice.actions

export default oauthSlice.reducer