import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    'selectedProject': {},
    'drivers': []
}

export const compassSlice = createSlice({
    name: 'compass',
    initialState,
    reducers: {
        setSelectedProject: (state, value) => {
            console.log(value);
            state.selectedProject = value.payload;
        },
        setDrivers: (state, value) => {
            state.drivers = value.payload
        }
    }
})

export const { setSelectedProject, setDrivers } = compassSlice.actions;

export default compassSlice.reducer;